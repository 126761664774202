import { getAuth } from "@clerk/remix/ssr.server";
import { LoaderFunction } from "@remix-run/node";
import { useLoaderData, Link, redirect } from "@remix-run/react";
import { getUserProjects } from "~/utils/projects.server";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { CirclePlus, ListOrdered } from "lucide-react";

export const loader: LoaderFunction = async (args) => {
  const { sessionClaims } = await getAuth(args);
  if (!sessionClaims) {
    return redirect("/sign-in");
  }
  const projects = await getUserProjects(sessionClaims.org_slug);
  return { projects };
};

export default function Index() {
  const { projects } = useLoaderData<typeof loader>();

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Your Projects</h1>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {projects.map((project) => (
          <Card key={project.key} className="overflow-hidden">
            <CardHeader>
              <Link
                to={`/issues/${project.key}`}
                className="block hover:bg-orange-200 rounded-md transition-colors"
                title="View Issues"
              >
                <div className="flex items-center gap-2">
                  <ListOrdered className="h-4 w-4" />
                  <CardTitle>{project.name}</CardTitle>
                </div>
                <CardDescription>Project Key: {project.key}</CardDescription>
              </Link>
            </CardHeader>
            <CardContent>
              <Button
                asChild
                variant="outline"
                className="w-full hover:bg-orange-200 rounded-md transition-colors"
              >
                <Link to={`/projects/${project.key}`}>
                  <CirclePlus className="ml-2 h-4 w-4" />
                  New issue
                </Link>
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
